<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <!-- invalid -->
      <form autocomplete="off">
        <!-- Lead -->
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12">
            <!-- Name -->
            <v-row dense class="pa-0 ma-0">
              <v-col
                xl="7" lg="7" md="7" sm="7"
                cols="12" class="py-0"
              >
                <mb-input-text
                  v-if="lead && lead.type == 'pf'"
                  v-model="lead.name"
                  name="scntld_name" label="Nome"
                  rules="required|max:60"
                />
                <mb-input-text
                  v-else
                  v-model="lead.name"
                  name="scntld_name" label="Nome"
                  rules="required|max:150"
                />
              </v-col>
            </v-row>
            <!-- Email -->
            <v-row dense class="pa-0 ma-0">
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12" class="py-0">
                <mb-input-text
                  v-model.trim="lead.email"
                  name="scntld_email" label="Email"
                  rules="required|max:150"
                />
              </v-col>
              <!-- Phone -->
              <v-col
                xl="2" lg="2" md="4" sm="4"
                cols="12" class="py-0">
                <mb-input-phone
                  v-model="lead.mobile"
                  name="scntld_mobile" label="Celular" rules="required|phone"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row dense class="pa-0 ma-0">
          <v-col cols="12">
            <v-alert v-if="message" text :type="message.type">
              {{message.txt}}
            </v-alert>
          </v-col>
          <v-col
            xl="3" lg="3" md="3" sm="3"
            cols="12" class="py-0"
            v-if="!showFull">
              <mb-text-button
                name="scntld_btn_cancel" label="Continuar" color="accent"
                class="mb-2" @click="showFull = true; $emit('contratar');"
                :disabled="invalid"
              />
          </v-col>
          <v-col
            xl="2" lg="2" md="2" sm="2"
            cols="12" class="py-0">
              <mb-text-button
                name="scntld_btn_save" label="Falar com Consultor"
                :loading="saveBtnLoading"
                class="ml-2 mb-2"
                @click="saveLead"
                :disabled="invalid"
              />
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      saveBtnLoading: false,
      showFull: false,
      lead: {
        estipulanteId: null,
        name: null,
        email: null,
        mobile: null,
        type: null,
      },
      message: null,
    };
  },
  watch: {
    value() {
      this.lead = this.value;
    },
  },
  created() {
    this.lead = this.value;
  },
  methods: {
    saveLead() {
      this.saveBtnLoading = true;
      this.$store
        .dispatch('lead/create', this.lead)
        .then(() => {
          this.message = {
            type: 'success',
            txt: 'Em breve um de nossos consultores entrará em contato.',
          };
          this.$emit('input', this.lead);
        })
        .catch((err) => {
          if (err && err.reponse && err.response.data) {
            console.error(err.response.data.message);
          } else {
            console.error('Erro ao salver lead');
          }
        })
        .finally(async () => {
          this.saveBtnLoading = false;
        });
    },
  },
};
</script>
