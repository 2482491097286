<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Lead -->
          <v-row dense class="pa-0 ma-0 mb-2">
            <v-col cols="12">
              <lead v-model="lead" @contratar="showFull = true"/>
            </v-col>
          </v-row>

          <v-divider v-if="showFull" />

          <!-- Complete -->
          <v-row v-if="showFull" dense class="pa-0 ma-0 mt-4">
            <v-col cols="12">
              <v-row dense class="pa-0 ma-0 mb-4">
                <v-col
                  xl="4" lg="4" md="8" sm="8"
                  cols="12" v-if="params && params.type.includes('vinc')"
                >
                  <mb-select
                    v-model="subestipulante" :items="subestipulantes"
                    item-text="slug" name="scnt_subestipulante" label="Sindicato"
                    :loading="subestipulantesLoading"
                  />
                </v-col>
              </v-row>
              <!-- Document -->
              <v-row dense class="pa-0 ma-0">
                <v-col
                  xl="2" lg="2" md="3" sm="4"
                  cols="12"
                >
                  <mb-input-cnpj-verify
                    v-model="contratante.document"
                    name="scnt_cnpj" label="CNPJ"
                    rules="required"
                    @valided="(valid) => { cnpjVerified = valid;}"
                    @company="(c) => { (c) ? contratante = c : contratante = defaultContratante;}"
                  />
                </v-col>
              </v-row>
              <v-row dense class="pa-0 ma-0">
                <v-col
                  xl="5" lg="5" md="5" sm="6"
                  cols="12"
                >
                  <mb-input-text
                    v-model="contratante.slug"
                    name="scnt_slug" label="Nome Divulgação"
                    rules="required|max:50"
                  />
                </v-col>
              </v-row>
              <v-row dense class="pa-0 ma-0">
                <v-col
                  xl="5" lg="5" md="5" sm="5"
                  cols="12">
                  <mb-input-text
                    v-model="contratante.contact"
                    name="scnt_contact" label="Contato"
                    rules="required|max:50"
                  />
                </v-col>
                <v-col
                  xl="2" lg="2" md="3" sm="3"
                  cols="12">
                  <mb-input-phone
                    v-model="contratante.phone"
                    name="scnt_phone" label="Telefone"
                  />
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0">
                  <address-form v-model="contratante"/>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions v-if="showFull" class="mt-0">
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12" class="mt-0">
            <v-alert v-if="message" text :type="message.type" class="mt-0">
              {{message.txt}}
            </v-alert>
          </v-col>
          <v-col
            xl="3" lg="3" md="3" sm="3"
            cols="12" class="py-0">
            <mb-text-button
              name="prd_btn_save" label="Salvar"
              :disabled="invalid || !cnpjVerified"
              :loading="saveBtnLoading"
              class="ml-2 mb-2"
              @click="save"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </ValidationObserver>
  </v-container>

</template>

<script>
import { ValidationObserver } from 'vee-validate';
import Lead from '@/components/contratante/Lead';
import stateList from '@/utils/states';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCnpjVerify from '@/components/general/inputs/MbInputCnpjVerify';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    Lead,
    MbInputText,
    MbInputPhone,
    MbInputCnpjVerify,
    MbSelect,
    MbTextButton,
    AddressForm,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      contratante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        type: 'pj',
      },
      showFull: false,
      message: null,
      lead: {
        estipulanteId: null,
        name: null,
        email: null,
        mobile: null,
        type: 'pj',
      },
      subestipulantes: [],
      subestipulante: null,
      subestipulantesLoading: false,
      states: stateList,
      cnpjVerified: true,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.lead.estipulanteId = this.params.e;
    if (this.params.type.includes('vinc')) {
      this.getSubestipulantes();
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();

      const params = {};

      if (isValid) {
        this.contratante.name = this.lead.name;
        this.contratante.email = this.lead.email;
        this.contratante.mobile = this.lead.mobile;

        params.contratante = this.contratante;

        params.estipulanteId = this.params.e;

        if (this.params && this.params.type.includes('vinc') && this.subestipulante) {
          params.subestipulanteId = this.subestipulante.id;
        }

        this.saveBtnLoading = true;
        this.$store
          .dispatch('contratante/createFromSite', params)
          .then(() => {
            this.message = {
              type: 'success',
              txt: 'Cadastro salvo com sucesso! Mandamos email com dados de acesso!',
            };
          })
          .catch((err) => {
            console.error(err.response.data.message);
            this.message = {
              type: 'error',
              txt: 'Erro ao salvar cadastro!',
            };
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    getSubestipulantes() {
      this.subestipulantesLoading = true;
      this.$store.dispatch('subestipulante/allSite', this.params.e)
        .then((resp) => {
          this.subestipulantes = resp;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.subestipulantesLoading = false;
        });
    },
  },
};
</script>
