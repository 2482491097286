<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Lead -->
          <v-row dense class="pa-0 ma-0 mb-2">
            <v-col cols="12">
              <lead v-model="lead" @contratar="showFull = true"/>
            </v-col>
          </v-row>

          <v-divider v-if="showFull" />

          <!-- Complete -->
          <v-row v-if="showFull" dense class="pa-0 ma-0 mt-4">
            <v-col cols="12">
              <v-row dense class="pa-0 ma-0 mb-4" v-if="params && params.type.includes('vinc')" >
                <v-col
                  xl="4" lg="4" md="8" sm="8"
                  cols="12"
                >
                  <mb-select
                    v-model="subestipulante" :items="subestipulantes"
                    item-text="slug" name="scnt_subestipulante" label="Sindicato"
                    :loading="subestipulantesLoading"
                  />
                </v-col>
              </v-row>

              <!-- Document -->
              <v-row dense class="pa-0 ma-0">
                <v-col
                  xl="4" lg="4" md="4" sm="4"
                  cols="12"
                >
                  <mb-input-cpf
                    v-model="contratante.document"
                    name="scnt_cpf" label="CPF"
                    rules="required"
                  />
                </v-col>
                <v-col
                  xl="4" lg="4" md="4" sm="4"
                  cols="12">
                  <mb-input-phone
                    v-model="contratante.phone"
                    name="scnt_phone" label="Telefone"
                  />
                </v-col>
              </v-row>
              <!-- Birthday/Genre -->
              <v-row dense class="pa-0 ma-0">
                <v-col
                  xl="4" lg="4" md="4" sm="4"
                  cols="12"
                >
                  <mb-date
                    v-model="contratante.birthdate"
                    name="cpny_birthdate"
                    label="Data de Nascimento"
                    rules="required"
                    :allowedDates="(vl) => { return $moment(vl).isBefore($moment()) }"
                  />
                </v-col>
                <v-col
                  xl="4" lg="4" md="4" sm="4"
                  cols="12"
                >
                  <mb-select
                    v-model="contratante.genre"
                    :items="sexItems"
                    itemText="text"
                    itemValue="vl"
                    name="cpny_sex"
                    label="Sexo"
                  />
                </v-col>
              </v-row>

              <v-divider />

              <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0">
                  <address-form v-model="contratante" />
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions v-if="showFull" class="mt-0">
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12" class="mt-0">
            <v-alert v-if="message" text :type="message.type" class="mt-0">
              {{message.txt}}
            </v-alert>
          </v-col>
          <v-col
            xl="3" lg="3" md="3" sm="3"
            cols="12" class="py-0">
            <mb-text-button
              name="prd_btn_save" label="Salvar"
              :disabled="invalid || contratante.id != null"
              :loading="saveBtnLoading"
              class="ml-2 mb-2"
              @click="save"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </ValidationObserver>
  </v-container>

</template>

<script>
import { ValidationObserver } from 'vee-validate';
import Lead from '@/components/contratante/Lead';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbDate from '@/components/general/inputs/MbDate';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    Lead,
    MbInputPhone,
    MbInputCpf,
    MbSelect,
    MbDate,
    MbTextButton,
    AddressForm,
  },
  props: {
    params: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      contratante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,
        birthdate: null,
        genre: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        type: 'pf',
      },
      showFull: false,
      message: null,
      lead: {
        estipulanteId: null,
        name: null,
        email: null,
        mobile: null,
        type: 'pf',
      },
      subestipulantes: [],
      subestipulante: null,
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
    };
  },
  computed: {
  },
  watch: {
    contratante: {
      handler() {
        if (this.contratante.type === 'pf') {
          this.contratante.slug = this.lead.name;
          this.contratante.contact = this.lead.name;
        }
      },
      deep: true,
    },
  },
  created() {
    this.lead.estipulanteId = this.params.e;
    if (this.params.type.includes('vinc')) {
      this.getSubestipulantes();
    }
  },
  methods: {
    getSubestipulantes() {
      this.subestipulantesLoading = true;

      this.$store.dispatch('subestipulante/allSite', this.params.e)
        .then((resp) => {
          this.subestipulantes = resp;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.subestipulantesLoading = false;
        });
    },
    async save() {
      const isValid = await this.$refs.observer.validate();

      const params = {};

      if (isValid) {
        this.contratante.name = this.lead.name;
        this.contratante.email = this.lead.email;
        this.contratante.mobile = this.lead.mobile;

        params.contratante = this.contratante;

        params.estipulanteId = this.params.e;

        if (this.params && this.params.type.includes('vinc') && this.subestipulante) {
          params.subestipulanteId = this.subestipulante.id;
        }

        this.saveBtnLoading = true;
        this.$store
          .dispatch('contratante/createFromSite', params)
          .then((resp) => {
            this.contratante = resp;
            this.message = {
              type: 'success',
              txt: 'Cadastro salvo com sucesso!',
            };

            this.$emit('input', this.contratante);
            setTimeout(() => { this.$emit('saved'); }, 3000);
          })
          .catch((err) => {
            console.error(err.response.data.message);
            this.message = {
              type: 'error',
              txt: 'Erro ao salvar cadastro!',
            };
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },
};
</script>
